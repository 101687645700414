@import "styles/style";

@import "components/header";
// @import "components/aside";
@import "components/tables";
@import "components/reports";
@import "components/appointments";
//@import "~@swimlane/ngx-datatable/index.css";
//@import "~@swimlane/ngx-datatable/themes/material.css";
//@import "~@swimlane/ngx-datatable/assets/icons.css";
// @import '../demo/default/base/style.bundle.css';
//@import "~ngx-toastr/toastr.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~angular-tree-component/dist/angular-tree-component.css";



.toast {
  opacity: 1 !important;
}

.m-stack__item.m-brand.m-brand--skin-dark {
  background: #1b2945;
}

/* Logo for CUFI on Main page */
.header-svg {
  vertical-align: middle;
  border-style: none;
  height: 36px;
  align-items: left;
}

/* Light Blue Grid for sidebar. Maybe later
.m-aside-left--skin-dark {
    background: url(https://raw.githubusercontent.com/thoughtbot/refills/master/source/images/textures/crosses.png),linear-gradient(180deg,#286090,#1d4568),50% no-repeat #286090 scroll;
} */

// Global Modal - change z-index so it can be accessed
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100 !important;
  background-color: #000;
}

.selectedCUFIEvent {
  background-color: var(--secondary) !important;
  color: white;
}

.m-list-search .m-list-search__results .m-list-search__result-item:hover .m-list-search__result-item-text {
  color: #b19672;
}

.m-list-search .m-list-search__results .m-list-search__result-category {
  color: #1b2944;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

:root {
  --cufi_gold: #b19672;
  --cufi_blue: #1b2944;
  --cufi_secondary_blue: #435270;
  --metronic_table_head_row: #b2b4c7;
}

.m-header--fixed .m-body {
  padding-top: 25px !important;
}

// Keep ngx-datatable cell overflow hidden
.datatable-body-cell-label {
  overflow: hidden;
}

//Added as a workaround for popovers
.grid-with-popovers,
.grid-with-popovers:hover,
.grid-with-popovers *:hover {
  overflow: visible !important;
}

.registration-info-section {
  .fa,
  .fas,
  .fal,
  .far {
    text-align: center;
    width: 40px;
  }
}

.scrollable-table {
  width: 100%;
  background-color: #f3f3f3;

  tbody {
    overflow-y: auto;
    width: 100%;
  }

  thead,
  tbody,
  tr,
  td,
  th {
    display: block;
  }

  tbody {
    td {
      float: left;
    }
  }

  thead {
    tr {
      th {
        float: left;
      }
    }
  }
}

.sub-panel-tabs {
  li.nav-item {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  li.nav-item.active,
  li.nav-item.active:hover {
    border-bottom: 4px solid #7486ab;

    a {
      color: #1b2945;
      font-weight: 700;
    }
  }

  li.nav-item:hover {
    border-bottom: 4px solid #dce3f3;
  }

  li.nav-item a {
    color: #656c7b;
  }
}

.input-icon {
  position: absolute;
  left: 1rem;
  top: calc(50% - 0.5em);
  /* Keep icon in center of input, regardless of the input height */
}

.input-wrapper {
  position: relative;

  textarea {
    padding-left: 50px;
  }

  .bigicon {
    color: #a4a2bb;
  }
}

table.table {
  tr.active,
  tr.active td {
    background-color: #fff6dc !important;
  }
}

// In ngx-datatables, if part of group, change background color and text color
.is-inGroup {
  background-color: var(--cufi_secondary_blue);
}

.is-inGroup span {
  color: white;
}

// In ngx-datatable: to enable padding above and below cell contents, needs this height
// .datatable-body-cell {
//   height: 42px !important;
// }

// ------- Styling for ngx-datatable like metronic ----------
// ngx-datatable cursor pointer
.ngx-datatable {
  cursor: pointer;
}

.datatable-header-cell {
  font-weight: 600;
  font-size: 1rem;
}

.datatable-body-cell {
  vertical-align: middle;
  padding: 11px 10px;
  font-size: 1rem;
}

.datatable-header {
  // background-color: var(--metronic_table_head_row);
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  padding: 16px 10px;
}

.select2-selection--multiple,
.select2-results {
  width: 350px;
}

.full-width-select2-container {
  .select2-selection--multiple,
  .select2-results {
    width: 100%;
    min-height: 100px;
  }
}

.select2-dropdown {
  width: 350px;
}

.select2-dropdown--below {
  width: 350px !important;
}

.select2-search__field {
  width: 100% !important;
}

//m-checkbox m-checkbox--single m-checkbox--all m-checkbox--solid m-checkbox--brand These are the checkbox styling to replace datatable-checkbox
//
//
// ----------- checkbox styling -- problem with ::after for now --------------
// .datatable-checkbox {
//     top: 2px;
//     padding: 0;
//     margin: 0 0 0 0;
//     width: 18px;
//     height: 18px;
//     background: #e5e3ef;
//     border: 1px solid transparent !important;
//     -webkit-border-horizontal-spacing: 1.6px;
//     -webkit-border-radius: 3px;
//     moz-border-radius: 3px;
//     -ms-border-radius: 3px;
//     -o-border-radius: 3px;
//     border-radius: 3px;
// }

// .datatable-checkbox input {
//     position: absolute;
//     z-index: -1;
//     opacity: 0;
//     filter: alpha(opacity=0);
// }
// for later possibly ---------------
// input[type="checkbox"] {
//     box-sizing: border-box;
//     padding: 0;
// }
//
// .m-checkbox > span:after {
//     top: 50%;
//     left: 50%;
//     margin-left: -2px;
//     margin-top: -6px;
//     width: 5px;
//     height: 10px;
//     border-width: 0 2px 2px 0 !important;
//     transform: rotate(45deg);
// }
//
// ---Checkbox before current version
// .datatable-checkbox input[type='checkbox']:before {
//     -webkit-transition: all 0.3s ease-in-out;
//     transition: all 0.3s ease-in-out;
//     content: "";
//     position: absolute;
//     left: 0;
//     z-index: 1;
//     width: 1rem;
//     height: 1rem;
//     border: 2px solid #f2f2f2;
// }
.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: var(--cufi_secondary_blue);
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

// Align datatable body row text to header text; was pushed right
.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  padding: 0.75rem 0 0.75rem 0;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: center;
}

.hover-effect:hover {
  color: var(--primary);
  cursor: pointer;
}

.bg-hover-effect:hover {
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}

.hover-danger:hover {
  color: var(--danger);
  cursor: pointer;
}

// Center cell text in ngx-datatable
.datatable-body-cell-label {
  text-align: center;
}

// Bold all Header Cell Labels ngx-datatable
.datatable-header-cell-label {
  font-weight: bold;
}

.datatable-header-cell-template-wrap {
  font-weight: bold;
}

// Button styling specific to em3
.btn-accent-secondary_blue {
  background-color: var(--cufi_secondary_blue);
  border-color: var(--cufi_blue);
}

.la-group-whitesmoke {
  color: whitesmoke;
}

// floats createGroup and createTable buttons to right and adds space between
.addBtns-float-rt {
  float: right;
  margin: 0 4px;
}

// Remove background color and bottom border current from ngx-datatable header row/cells
.ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: transparent !important;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  border-bottom: none;
}

// Select 2
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--multiple .select2-selection__arrow {
  font-family: "FontAwesome";
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  border: 0;
  top: 50%;
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  font-size: 0.85rem;
  left: auto;
  display: inline-block;
  width: 1.9rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow:before,
.select2-container--default .select2-selection--multiple .select2-selection__arrow:before {
  content: "\f0dc";
  // background: #fff url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' v…0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E) no-repeat right 0.75rem center;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: -15px;
}

.datatable-body-cell.datatable-body-cell.sort-active {
  width: 100%;
}

.datatable-body.datatable-body-cell:focus {
  width: 100%;
}

// Global styling to change backgrounds to CUFI colors
.em3--bg-blue {
  background-color: var(--cufi_blue) !important;
}

.em3--bg-secondary-blue {
  background-color: var(--cufi_secondary_blue) !important;
}

// Representative Alignment center horizontal
.representative-letterhead {
  text-align: center;
}

// begin::Simple HTML TABLE stylings -----------------
.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #f9f9f9;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #ffffff;
}

.th-bold {
  font-weight: bold !important;
}

// -----------------  end::Simple HTML TABLE stylings
.reportForPrint {
  width: 100%;
}

.reportForPrint td,
#reportForPrint th {
  border: 1px solid #ddd;
  padding: 8px;
}

.reportForPrint tr:nth-child(even) {
  background-color: #f2f2f2;
}

.reportForPrint tr:hover {
  background-color: #ddd;
}

.reportForPrint th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--cufi_blue);
  color: white;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #42a948;
}

input:focus + .slider {
  box-shadow: 0 0 1px #42a948;
}

input:checked + .slider.disabled {
  background-color: #7ba07d;
}

input:focus + .slider.disabled {
  box-shadow: 0 0 1px #7ba07d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  padding-left: 10px;
}

.has-danger .form-control {
  border-color: #f4516c;
}

.fa-clock-o:before {
  content: "\f017";
}

.xsmall-icon {
  font-size: 12px;
}

.small-icon {
  font-size: 16px;
  margin-right: 5px;
}

tr td i:last-child {
  margin-right: 0;
}

.medium-icon {
  font-size: 20px;
}

.large-icon {
  font-size: 24px;
}

.xlarge-icon {
  font-size: 32px;
}

.has-feedback .ng-invalid.ng-touched,
.has-error .ng-invalid.ng-touched {
  border-color: #f4516c;
}

.switch.small {
  height: 26px;
  width: 52px;
  .slider.round {
    border-radius: 26px;
  }
  .slider:before {
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
  }
}

.ng-select-compact-container {
  ng-select {
    padding-bottom: 0;

    .ng-select-container {
      min-height: unset;
    }

    .ng-dropdown-panel {
      margin: 20px 0;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 1.5em;
  padding: 10px 16px;
}

.datatable-header {
  border-bottom: 1px solid #dee2e6 !important;
  border-top: 1px solid #dee2e6 !important;
}

.dashboard-datatable {
  height: 320px;
}

.color-chooser {
  option:hover {
    background-color: red !important;
  }
}

.intials-circled {
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 35px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  background-color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.btn.btn-outline-logout {
  color: #273268;
}
.btn.btn-outline-focus.active,
.btn.btn-outline-focus.focus,
.btn.btn-outline-focus:focus,
.btn.btn-outline-focus:hover:not(:disabled) {
  color: #ffffff !important;
}

// datatable-header, datatable-scroller, datatable-body-row, .datatable-row-center, .datatable-header-inner {
//   width: 100% !important;
// }

.row-strike-through td {
  text-decoration: line-through;
}

.strike-through {
  text-decoration: line-through;
}

.no-strike-through {
  text-decoration: none !important;
}

.m-portlet.compact {
  margin-bottom: 1.2rem !important;

  .m-portlet__head {
    height: 55px;

    .m-portlet__head-text {
      font-size: 1.2rem !important;
    }
  }
}

.m-subheader {
  font-weight: 500;
  font-size: larger;
}

.form-control[readonly],
.form-control {
  border-color: #bebdbf;
  color: #575962;
  background-color: inherit;
}

.form-control[disabled] {
  border-color: #bebdbf;
  color: #575962;
}

.input-group-prepend {
  border: 1px solid #bebdbf;
  border-radius: 3px;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #f4f5f8;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: #f4f5f8;
}

span.bootstrap-switch-handle-off.bootstrap-switch-default {
  border-color: #bebdbf;
}

.form-control.m-input--solid {
  border-color: #bebdbf;
  color: #575962;
}

.btn-label {
  position: relative;
  left: -15px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
  [class^="fa-"],
  [class*=" fa-"] {
    color: inherit;
  }
}
.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}


label.datatable-checkbox input:not(:checked)::before {
  border: 2px solid #999 !important;
}

.table-plain tbody tr,
.table-plain tbody tr:hover,
.table-plain tbody td,
.table-plain tbody th
 {
  background-color:transparent;
}



.note-editor .note-toolbar {
  z-index: auto;
  padding: 2px 10px 0 !important;
}
.note-editor .note-toolbar > .note-btn-group {
  padding-bottom: 9px;
}

/* HEADER: Change global icon hover color from purples  */
.m-header-menu.m-header-menu--skin-light .m-menu__nav > .m-menu__item:hover > .m-menu__link .m-menu__link-icon, .m-header-menu.m-header-menu--skin-light .m-menu__nav > .m-menu__item.m-menu__item--hover > .m-menu__link .m-menu__link-icon {
color: #1b2945; }

/* HEADER: Change global text hover color from purples  */
.m-header-menu.m-header-menu--skin-light .m-menu__nav > .m-menu__item:hover > .m-menu__link .m-menu__link-text, .m-header-menu.m-header-menu--skin-light .m-menu__nav > .m-menu__item.m-menu__item--hover > .m-menu__link .m-menu__link-text {
color: #1b2945; }

/* HEADER: Change global arrow hover color from purples  */
.m-header-menu.m-header-menu--skin-light .m-menu__nav > .m-menu__item:hover > .m-menu__link > .m-menu__hor-arrow, .m-header-menu.m-header-menu--skin-light .m-menu__nav > .m-menu__item.m-menu__item--hover > .m-menu__link > .m-menu__hor-arrow {
color: #1b2945; }

/* HEADER: Change badge colors to blue from purples */
.m-badge.m-badge--brand {
	background-color: #1b2945;
}

/* Circular buttons */
.btn-circle {
    border-radius: 25px!important;
    overflow: hidden;
}

/* Event Dropdown */
.event-dropdown {
    transform: translateY(45%);
    float: left;
}

a {
	color: #1b2945;
}

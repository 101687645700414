.centered_table_heading {
	line-height: 226px;
}

  .m-datatable > .m-datatable__table > .m-datatable__head .m-datatable__row > .m-datatable__cell,
  .m-datatable > .m-datatable__table > .m-datatable__foot .m-datatable__row > .m-datatable__cell {
    background: #B2B4C7; }

// For M Widgets
.m--bg-brand {
	background-color: var(--cufi_secondary_blue) !important;
}

.m--font-brand {
    color: var(--cufi_secondary_blue) !important;
}

.m-datatable.m-datatable--subtable > .m-datatable__table > .m-datatable__body .m-datatable__toggle-subtable > i {
    color: var(--cufi_blue) !important;
}

a:hover {
    color: var(--cufi_gold);
}

// Bootstrap themes for ngx-datatable
/*
bootstrap table theme
*/
.ngx-datatable {
  overflow-x: auto;
}

.ngx-datatable.bootstrap {
	font-weight: 1rem;
  box-shadow: none;
  font-size: 13px;
  .datatable-header {
    height: unset !important;
    .datatable-header-cell {
      vertical-align: middle;
      padding: 0.75rem;
      border-bottom: 1px solid #d1d4d7;
      .datatable-header-cell-label {
        line-height: 24px;
      }
    }
  }
  .datatable-body {
    width: auto;
    .datatable-body-row {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #d1d4d7;
      &.datatable-row-even {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &.active {
        background-color: rgba(0, 0, 0, 0.05) !important;
        color: #FFF;
      }
      .datatable-body-cell {
        text-align: left;
        vertical-align: middle;
      }
    }
  }
  .datatable-footer {
    background: #424242;
    color: #ededed;
    margin-top: -1px;
    .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem;
    }
    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;
      ul {
        li {
          margin: 10px 0px;
          &:not(.disabled) {
            &.active,
            &:hover {
              a {
                background-color: #545454;
                font-weight: bold;
              }
            }
          }
        }
      }
      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: #ededed;
      }
      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: left;
    padding: 11px 10px;
    vertical-align: top;
    border-top: 0;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
    font-size: 1rem;
    font-weight: 300;
    vertical-align: middle;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
    text-align: left;
    padding: 16px 10px;
    font-weight: 600;
    color: #575962;
    // background-color: #B2B4C7;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 500;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
    background-color: #faf9fc;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
    border-top: none;
}

.ngx-datatable.material .datatable-header {
    border-bottom: none;
}

.ngx-datatable.material .datatable-header .datatable-body {
    border-bottom: none;
}

.m-portlet--ngx {
  width: 100%;
  // min-height: 847.8px;
}

.datatable-body-row:hover {
  overflow: hidden;
}

// To align la-gear in ngx-datatable
.m-btn--icon.m-btn--icon-only {
    display: inline-block;
    position: relative;
    padding: 0 !important;
    width: 33px;
    height: 22px;
}

// Table Assignments row padding top and bottom
.byAttendeeRowPad {
  padding: 4px 0;
}

// Select Existing Group Label styling so that Label displays full width
.selectExistingGroup {
  width: 150px
}

// .table {
//   width: 100%;
//   max-width: 100%;
//   margin-bottom: 1rem;
//   background-color: transparent; }
//   .table th,
//   .table td {
//     padding: 0.75rem;
//     vertical-align: top;
//     border-top: 1px solid #f4f5f8; }
//   .table thead th {
//     vertical-align: bottom;
//     border-bottom: 2px solid #f4f5f8; }
//   .table tbody + tbody {
//     border-top: 2px solid #f4f5f8; }
//   .table .table {
//     background-color: #fff; }

// .table-striped tbody tr:nth-of-type(odd) {
//   background-color: #f4f5f8; }


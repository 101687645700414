//
//** Override The Base Framework Config For This Demo
//

//== Example(reduce font size from 14px to 13px globally for desktop mode only)

//== The body element font settings
/**
$m--root-font: (
    size: (
        desktop: 13px,
        tablet: 13px,
        mobile: 13px
    ), 
    weight: 300, 
    color: (
        light: #333333, 
        dark: #efefef
    )
);
*/

$m--brand-color: #716aca;
$m--brand-inverse-color: #ffffff;
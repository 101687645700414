// STYLING FOR APPOINTMENT VIEWS 

// Size columns in "Representative" table display
.table.table-striped.m-table th {
    width: 200px;
}
    // Vertically center data in table row
    .table.table-striped.m-table td {
        vertical-align: middle;
    }

// Centering row for Representatives
.centeredLetterhead {
    display: flex;
    align-items: center;
}

.grid-container {
    display: grid;
    grid-template-columns: 100px 126px 126px 1fr;
    grid-template-rows: 25% auto;
  }

.centered-img-grid {
    justify-content: center;
    align-items: center;
    display: flex;
}

.centered-representative-description {
    position: absolute;
    top: 50%;
    height: 10em;
    margin-top: -5em;
}

// Color ngx-datatable footers to cufi_blue
.ngx-datatable.bootstrap .datatable-footer {
    background: var(--cufi_blue);
}

// Change edit button text color to cufi_blue
.cufi-button-text-blue {
    color: var(--cufi_blue)!important;
}

// District Attendee table specific
.district-attendee-row{
    padding: 0rem!important;
}
.district-attendee-row .datatable-body-cell{
font-size: 0.9rem!important;
}

.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
    background-color: rgb(223, 217, 217)!important;
    color: #FFF;
}
